import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {fetchCountriesData} from './thunks';
import {fetchRegionalData} from 'redux/RegionalView/thunks';
import {CountryDataRow, SelectedCountry} from 'models/CountryData';

interface IPopup {
    isOpen: boolean;
    countryCode: string;
}

interface AppState {
    isLoading: boolean;
    isMapLoading: boolean;
    error: string | undefined;
    countriesData: CountryDataRow[];
    totalNumberOfCases: number;
    selectedCountryInSideBar: SelectedCountry | null;
    lastUpdateDate: string;
    popup: IPopup;
}

const initialState: AppState = {
    isLoading: false,
    isMapLoading: false,
    error: undefined,
    countriesData: [],
    totalNumberOfCases: 0,
    selectedCountryInSideBar: null,
    lastUpdateDate: '',
    popup: {
        isOpen: false,
        countryCode: '',
    },
};

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setIsMapLoading: (state, action: PayloadAction<boolean>) => {
            state.isMapLoading = action.payload;
        },
        setSelectedCountryInSidebar: (
            state,
            action: PayloadAction<SelectedCountry | null>,
        ) => {
            state.selectedCountryInSideBar = action.payload;
        },
        setLastUpdateDate: (state, action: PayloadAction<string>) => {
            state.lastUpdateDate = action.payload;
        },
        setPopup: (state, action: PayloadAction<IPopup>) => {
            state.popup = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchCountriesData.pending, (state) => {
            state.isLoading = true;
            state.error = undefined;
        });
        builder.addCase(fetchCountriesData.fulfilled, (state, {payload }) => {
            state.isLoading = false;
            state.countriesData = payload.countriesData;
            state.totalNumberOfCases = payload.totalNumberOfCases;
            state.lastUpdateDate = payload.lastUpdateDate;
        });
        builder.addCase(fetchCountriesData.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload
                ? action.payload
                : action.error.message;
        });

        // Regional view (error handling)
        builder.addCase(fetchRegionalData.pending, (state) => {
            state.error = undefined;
        });
        builder.addCase(fetchRegionalData.rejected, (state, action) => {
            state.error = action.payload
                ? action.payload
                : action.error.message;
        });
    },
});

export const {
    setSelectedCountryInSidebar,
    setPopup,
} = appSlice.actions;

export default appSlice.reducer;
