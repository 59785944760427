import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import {useState, useEffect, SyntheticEvent} from 'react';

import {CountryDataRow, SelectedCountry} from 'models/CountryData';
import {
    selectCountriesData,
    selectLastUpdateDate,
    selectTotalCases,
    selectTotalCasesIsLoading,
    selectSelectedCountryInSideBar
} from 'redux/App/selectors';
import {setSelectedCountryInSidebar, setPopup} from 'redux/App/slice';
import {useAppDispatch, useAppSelector} from 'redux/hooks';
import {convertStringDateToDate, getCountryName, getCountryISO2} from 'utils/helperFunctions';


import {
    FlagIcon,
    LatestGlobal,
    LocationList,
    LocationListItem,
    SearchBar,
    SideBarHeader,
    StyledSideBar,
    SideBarTitlesSkeleton,
    CountriesListSkeleton,
    CountryLabel, CountryCaseCount, CaseCountsBar, EmptyFlag
} from './styled';
import { selectRegionalData } from 'redux/RegionalView/selectors';

const SideBar = () => {
    const [openSidebar, setOpenSidebar] = useState(true);
    const [autocompleteData, setAutocompleteData] = useState<SelectedCountry[]>(
        [],
    );

    const dispatch = useAppDispatch();

    const countriesData = useAppSelector(selectCountriesData);
    const lastUpdateDate = useAppSelector(selectLastUpdateDate);
    const regionalData = useAppSelector(selectRegionalData);
    const selectedCountry = useAppSelector(selectSelectedCountryInSideBar);
    const totalCasesCount = useAppSelector(selectTotalCases);
    const totalCasesCountIsLoading = useAppSelector(selectTotalCasesIsLoading);

    const regionalView = location.pathname === '/region';

    const handleOnClick = () => {
        setOpenSidebar((value) => !value);
    };

    const handleOnCountryClick = (country: SelectedCountry) => {
        if (selectedCountry && country?.code === selectedCountry.code) {
            dispatch(setSelectedCountryInSidebar(null));
            dispatch(setPopup({isOpen: false, countryCode: 'worldwide'}));
        } else {
            dispatch(setSelectedCountryInSidebar(country));
            dispatch(setPopup({isOpen: true, countryCode: country.code}));
        }
    };

    const handleAutocompleteCountrySelect = (
        event: SyntheticEvent<Element, Event>,
        value: CountryDataRow | SelectedCountry | null,
    ) => {
        if (value === null) return;

        dispatch(setSelectedCountryInSidebar(value));
        dispatch(setPopup({isOpen: true, countryCode: value.code}));
    };

    useEffect(() => {
        if (regionalView) {
            const mappedData = regionalData.map((el) => {
                return {
                    _id: el.admin2 || '',
                    code: [el.country_code, el.long, el.lat].join('#'),
                };
            });
            // HIDE WORLDWIDE
            // mappedData = [{ _id: 'worldwide', code: ''  }, ...mappedData];

            setAutocompleteData(mappedData);
        } else {
            const mappedData = countriesData.map((el) => {
                let countryName = getCountryName(el.code);
                if (countryName === 'Taiwan, Province of China') countryName = 'Taiwan';
                return {
                    _id: countryName,
                    code: el.code,
                };
            });
            // HIDE WORLDWIDE
            // mappedData = [{ _id: 'worldwide', code: ''  }, ...mappedData];

            setAutocompleteData(mappedData);
        }
    }, [regionalData, countriesData, regionalView]);

    const Countries = () => {
        if (regionalView) {
            for (const country of countriesData) {
                return (
                    <>
                        {(regionalData.filter((w:any) => w.country_code == country.code) || []).map((district) => {
                            const { admin2, casecount, lat, long } = district;

                            const countryCasesCountPercentage =
                                (casecount / country.casecount) * 100;

                            const code = [country.code, long, lat].join('#')
                            const isActive = selectedCountry
                                ? selectedCountry.code === code
                                : false;

                            return (
                                <LocationListItem
                                    key={admin2}
                                    onClick={() => handleOnCountryClick({_id: admin2 || '', code})}
                                    data-cy="listed-country"
                                    isActive={isActive}
                                >
                                    <>
                                        <FlagIcon
                                            loading="lazy"
                                            src={`https://flagcdn.com/w20/${getCountryISO2(
                                                country.code,
                                            ).toLowerCase()}.png`}
                                            srcSet={`https://flagcdn.com/w40/${getCountryISO2(
                                                country.code,
                                            ).toLowerCase()}.png 2x`}
                                            alt={`${country.code} flag`}
                                        />
                                        <CountryLabel
                                            isActive={isActive}
                                            variant="body2"
                                        >
                                            {admin2}
                                        </CountryLabel>
                                    </>
                                    <CountryCaseCount
                                        isActive={isActive}
                                        variant="body2"
                                    >
                                        {casecount.toLocaleString()}
                                    </CountryCaseCount>
                                    <CaseCountsBar
                                        barWidth={countryCasesCountPercentage}
                                    />
                                </LocationListItem>
                            );
                        })}
                    </>
                );
            }
        }

        return (
            <>
                {countriesData.map((country) => {
                    const { _id, code, casecount } = country;

                    const countryCasesCountPercentage =
                        (casecount / casecount) * 100;

                    const isActive = selectedCountry
                        ? selectedCountry.code === code
                        : false;

                    return (
                        <LocationListItem
                            key={_id}
                            onClick={() => handleOnCountryClick({_id, code})}
                            data-cy="listed-country"
                            isActive={isActive}
                        >
                            <>
                                <FlagIcon
                                    loading="lazy"
                                    src={`https://flagcdn.com/w20/${getCountryISO2(
                                        code,
                                    ).toLowerCase()}.png`}
                                    srcSet={`https://flagcdn.com/w40/${getCountryISO2(
                                        code,
                                    ).toLowerCase()}.png 2x`}
                                    alt={`${code} flag`}
                                />
                                <CountryLabel
                                    isActive={isActive}
                                    variant="body2"
                                >
                                    {_id}
                                </CountryLabel>
                            </>
                            <CountryCaseCount
                                isActive={isActive}
                                variant="body2"
                            >
                                {casecount.toLocaleString()}
                            </CountryCaseCount>
                            <CaseCountsBar
                                barWidth={countryCasesCountPercentage}
                            />
                        </LocationListItem>
                    );
                })}
            </>
        );
    };

    return (
        <StyledSideBar
            $sidebaropen={openSidebar}
            data-cy="sidebar"
        >

            <SideBarHeader id="sidebar-header">
                <h1 id="total" className="sidebar-title total">
                    MARBURG LINE LIST CASES
                </h1>
                <br/>
                <div id="disease-selector"></div>
            </SideBarHeader>
            <LatestGlobal id="latest-global">
                {totalCasesCountIsLoading ? (
                    <SideBarTitlesSkeleton
                        animation="pulse"
                        variant="rectangular"
                        data-cy="loading-skeleton"
                    />
                ) : (
                    <>
                        <span id="total-cases" className="active">
                            {totalCasesCount.toLocaleString()}
                        </span>
                        <span id="p1-cases">NaN</span>
                        <span id="b1351-cases">NaN</span>
                        <span className="reported-cases-label">
                                    {' '}
                            confirmed cases
                        </span>
                    </>
                )}
                <div className="last-updated-date">
                    Last reported case:{' '}
                    {totalCasesCountIsLoading ? (
                        <SideBarTitlesSkeleton
                            animation="pulse"
                            variant="rectangular"
                            data-cy="loading-skeleton"
                        />
                    ) : (
                        <span id="last-updated-date">
                            {convertStringDateToDate(lastUpdateDate)}
                        </span>
                    )}
                </div>
            </LatestGlobal>

            <SearchBar className="searchbar">
                <Autocomplete
                    id="province-select"
                    options={autocompleteData}
                    autoHighlight
                    popupIcon={<></>}
                    getOptionLabel={(option) => {
                        return typeof option === 'string'
                            ? option
                            : option._id;
                    }}
                    onChange={(
                        event,
                        value: SelectedCountry | null,
                    ) => handleAutocompleteCountrySelect(event, value)}
                    isOptionEqualToValue={(option, value) =>
                        option.code === value.code
                    }
                    value={selectedCountry}
                    renderOption={(props, option) =>  (
                        <Box
                            component="li"
                            className="autocompleteBox"
                            {...props}
                        >
                            {option._id === 'worldwide' ? (
                                <EmptyFlag>-</EmptyFlag>
                            ) : (
                                <FlagIcon
                                    loading="lazy"
                                    width="20"
                                    src={`https://flagcdn.com/w20/${getCountryISO2(
                                        option.code.split('#')[0],
                                    ).toLowerCase()}.png`}
                                    srcSet={`https://flagcdn.com/w40/${getCountryISO2(
                                        option.code.split('#')[0],
                                    ).toLowerCase()}.png 2x`}
                                    alt={`${option.code.split('#')[0]} flag`}
                                />
                            )}

                            {option._id}
                        </Box>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Choose a country"
                            inputProps={{
                                ...params.inputProps,
                                'data-cy': 'autocomplete-input',
                            }}
                        />
                    )}
                />
            </SearchBar>
            <LocationList>
                {totalCasesCountIsLoading ? (
                    <CountriesListSkeleton
                        animation="pulse"
                        variant="rectangular"
                        data-cy="loading-skeleton"
                    />
                ) : (
                    <>
                        {/*HIDE WORLDWIDE*/}
                        {/*<LocationListItem*/}
                        {/*    onClick={() =>*/}
                        {/*        handleOnCountryClick({_id: "worldwide", code: ''})*/}
                        {/*    }*/}
                        {/*    data-cy="listed-country"*/}
                        {/*    isActive={*/}
                        {/*        selectedCountry*/}
                        {/*            ? selectedCountry._id ===*/}
                        {/*            'worldwide'*/}
                        {/*            : false*/}
                        {/*    }*/}
                        {/*>*/}
                        {/*    <>*/}
                        {/*        <EmptyFlag>-</EmptyFlag>*/}
                        {/*        <CountryLabel*/}
                        {/*            isActive={*/}
                        {/*                selectedCountry*/}
                        {/*                    ? selectedCountry._id ===*/}
                        {/*                    'worldwide'*/}
                        {/*                    : false*/}
                        {/*            }*/}
                        {/*            variant="body2"*/}
                        {/*        >*/}
                        {/*            Worldwide*/}
                        {/*        </CountryLabel>*/}
                        {/*    </>*/}
                        {/*    <CountryCaseCount*/}
                        {/*        isActive={*/}
                        {/*            selectedCountry*/}
                        {/*                ? selectedCountry._id ===*/}
                        {/*                'worldwide'*/}
                        {/*                : false*/}
                        {/*        }*/}
                        {/*        variant="body2"*/}
                        {/*    >*/}
                        {/*        {totalCasesCount.toLocaleString()}*/}
                        {/*    </CountryCaseCount>*/}
                        {/*</LocationListItem>*/}

                        <Countries />
                    </>
                )}
            </LocationList>

            <div id="sidebar-tab" onClick={handleOnClick}>
                <span id="sidebar-tab-icon">{openSidebar ? '◀' : '▶'}</span>
            </div>
        </StyledSideBar>
    );
};

export default SideBar;
