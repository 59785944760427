import mapboxgl, {MapSourceDataEvent, EventData} from 'mapbox-gl';
import ReactDOM from 'react-dom';
import {useRef, useEffect, useState} from 'react';

import Legend from 'components/Legend';
import Loader from 'components/Loader';
import MapPopup from 'components/MapPopup';
import countryLookupTable from 'data/admin0-lookup-table.json';
import {useMapboxMap} from 'hooks/useMapboxMap';
import {CountryViewColors} from 'models/Colors';
import {LegendRow} from 'models/LegendRow';
import {
    selectIsLoading,
    selectCountriesData,
    selectSelectedCountryInSideBar,
    selectPopupData,
} from 'redux/App/selectors';
import {setSelectedCountryInSidebar, setPopup} from 'redux/App/slice';
import {useAppSelector, useAppDispatch} from 'redux/hooks';
import {MapContainer} from 'theme/globalStyles';
import {
    convertStringDateToDate,
    getAdjustedLat,
    getAdjustedLng,
    getCountryName,
} from 'utils/helperFunctions';

import {PopupContentText} from './styled';


const dataLayers: LegendRow[] = [
    {label: '< 10', color: CountryViewColors['<10']},
    {label: '10-100', color: CountryViewColors['10-100']},
    {label: '100-500', color: CountryViewColors['100-500']},
    {label: '500-2k', color: CountryViewColors['500-2k']},
    {label: '2k-10k', color: CountryViewColors['2k-10k']},
    {label: '> 10k', color: CountryViewColors['10k+']},
];

const CountryView: React.FC = () => {
    const dispatch = useAppDispatch();

    const mapboxAccessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN || '';
    const lookupTableData = countryLookupTable.adm0.data.all as {
        [key: string]: any;
    };

    const countriesData = useAppSelector(selectCountriesData);
    const isLoading = useAppSelector(selectIsLoading);
    const popupData = useAppSelector(selectPopupData);
    const selectedCountry = useAppSelector(selectSelectedCountryInSideBar);


    const [mapLoaded, setMapLoaded] = useState(false);
    const [currentPopup, setCurrentPopup] = useState<mapboxgl.Popup>();

    const mapContainer = useRef<HTMLDivElement>(null);
    const map = useMapboxMap(mapboxAccessToken, mapContainer);

    // Fly to country
    useEffect(() => {
        if (!selectedCountry) {
            if (currentPopup) currentPopup.remove();
            return;
        }

        if (selectedCountry.code.length > 3) {
            dispatch(setSelectedCountryInSidebar(null));
            dispatch(setPopup({isOpen: false, countryCode: 'worldwide'}));
            return
        }

        const bounds = lookupTableData[selectedCountry.code]?.bounds;
        if (bounds) {
            map.current?.fitBounds(bounds);
        } else {
            if (currentPopup) currentPopup.remove();
            // Return to worldview
            map.current?.fitBounds([0, -12.4, 0, 70.15]);
        }

    }, [selectedCountry]);

    // Setup map
    useEffect(() => {
        const mapRef = map.current;
        if (!mapRef || isLoading) return;

        mapRef.on('load', () => {
            if (mapRef.getSource('countriesData')) {
                mapRef.removeSource('countriesData');
            }

            mapRef.addSource('countriesData', {
                type: 'vector',
                url: 'mapbox://mapbox.country-boundaries-v1',
            });

            // Setup map listener to check if map has loaded
            const setAfterSourceLoaded = (
                e: MapSourceDataEvent & EventData,
            ) => {
                if (e.sourceID !== 'countriesData' && !e.isSourceLoaded) return;
                displayCountriesOnMap();
                setMapLoaded(true);
                mapRef.off('sourcedata', setAfterSourceLoaded);
            };

            if (mapRef.isSourceLoaded('countriesData')) {
                displayCountriesOnMap();
                setMapLoaded(true);
            } else {
                mapRef.on('sourcedata', setAfterSourceLoaded);
            }
        });
    }, [isLoading]);

    // Display popup on the map
    useEffect(() => {
        const {isOpen, countryCode} = popupData;

        const mapRef = map.current;
        if (!isOpen || !countryCode || countryCode === '' || !mapRef || countryCode.length > 3) return;

        // Close previous popup if it exists
        if (currentPopup) currentPopup.remove();

        const country = countriesData.filter(
            (country) => country.code === countryCode,
        )[0];

        const caseCount = country.casecount;
        const lastUploadDate = convertStringDateToDate(country.lastUpdated);
        const lat = getAdjustedLat(country.lat, country.code);
        const lng = getAdjustedLng(country.long, country.code);
        const coordinates: mapboxgl.LngLatLike = {lng, lat};

        const countryName = getCountryName(countryCode);

        const popupContent = (
            <PopupContentText>
                {caseCount.toLocaleString()} confirmed case
                {caseCount > 1 ? 's' : ''}
            </PopupContentText>
        );

        // This has to be done this way in order to allow for React components as a content of the popup
        const popupElement = document.createElement('div');
        ReactDOM.render(
            <MapPopup
                title={countryName}
                content={popupContent}
                lastUploadDate={lastUploadDate}
            />,
            popupElement,
        );

        const popup = new mapboxgl.Popup()
            .setLngLat(coordinates)
            .setDOMContent(popupElement)
            .addTo(mapRef)
            .on('close', () =>
                dispatch(setPopup({isOpen: false, countryCode: ''})),
            );

        setCurrentPopup(popup);
    }, [popupData]);

    // Display countries data on the map
    const displayCountriesOnMap = () => {
        const mapRef = map.current;
        if (!countriesData || countriesData.length === 0 || !mapRef) return;

        for (const countryRow of countriesData) {
            const countryDataFromTable = lookupTableData[countryRow.code]
            if (countryDataFromTable) {
                mapRef.setFeatureState(
                    {
                        source: 'countriesData',
                        sourceLayer: 'country_boundaries',
                        id: countryDataFromTable.feature_id,
                    },
                    {
                        caseCount: countryRow.casecount,
                        code: countryDataFromTable.iso_3166_1,
                    },
                );
            }
        }

        // This fixes console errors when hot reloading app
        if (mapRef.getLayer('countries-join')) {
            mapRef.removeLayer('countries-join');
        }

        mapRef.addLayer(
            {
                id: 'countries-join',
                type: 'fill',
                source: 'countriesData',
                'source-layer': 'country_boundaries',
                paint: {
                    'fill-color': [
                        'case',
                        ['!=', ['feature-state', 'caseCount'], null],
                        [
                            'case',
                            ['<', ['feature-state', 'caseCount'], 10000],
                            CountryViewColors['<10'],
                            ['<', ['feature-state', 'caseCount'], 100000],
                            CountryViewColors['10-100'],
                            ['<', ['feature-state', 'caseCount'], 500000],
                            CountryViewColors['100-500'],
                            ['<', ['feature-state', 'caseCount'], 2000000],
                            CountryViewColors['500-2k'],
                            ['<', ['feature-state', 'caseCount'], 10000000],
                            CountryViewColors['2k-10k'],
                            ['>=', ['feature-state', 'caseCount'], 10000000],
                            CountryViewColors['10k+'],
                            CountryViewColors.Fallback,
                        ],
                        CountryViewColors.Fallback,
                    ],
                    'fill-outline-color': CountryViewColors.Outline,
                },
            },
            'admin-1-boundary',
        );

        //Filter out countries without any data
        mapRef.setFilter('countries-join', [
            'in',
            'iso_3166_1',
            ...countriesData.map((country) => lookupTableData[country.code].iso_3166_1),
        ]);

        // Change the mouse cursor to pointer when hovering above this layer
        mapRef.on('mouseenter', 'countries-join', () => {
            mapRef.getCanvas().style.cursor = 'pointer';
        });

        // Change it back when it leaves.
        mapRef.on('mouseleave', 'countries-join', () => {
            mapRef.getCanvas().style.cursor = '';
        });

        // Add click listener and show popups
        mapRef.on('click', 'countries-join', (e) => {
            if (!e.features || !e.features[0].state.code) return;

            const code = e.features[0].state.code;
            const alpha3Code = Object.values(lookupTableData).find((ltd: any) => ltd.iso_3166_1 === code).iso_3166_1_alpha_3
            const countryName = getCountryName(alpha3Code);

            dispatch(setSelectedCountryInSidebar({_id: countryName, code: alpha3Code}));
            dispatch(setPopup({isOpen: true, countryCode: alpha3Code}));
        });
    };

    return (
        <>
            {!mapLoaded && <Loader/>}
            <MapContainer
                ref={mapContainer}
                isLoading={isLoading || !mapLoaded}
            />
            <Legend title="Confirmed cases" legendRows={dataLayers}/>
        </>
    );
};

export default CountryView;
