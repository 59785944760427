import { format, parseISO } from 'date-fns';
import { RegionalData } from 'models/RegionalData';
import { Feature, FeatureSet } from 'models/FeatureSet';
import iso from 'iso-3166-1';

// Regional data has to be converted to GeoJson type in order to be displayed on the map
export const convertRegionalDataToFeatureSet = (
    data: RegionalData[]
): FeatureSet => {
    const featureSet: FeatureSet = { type: 'FeatureCollection', features: [] };

    for (const dataRow of data) {
        const feature: Feature = {
            type: 'Feature',
            properties: {
                id: dataRow._id,
                caseCount: dataRow.casecount,
                country: dataRow.country_code,
                region: dataRow._id,
                search: dataRow.search,
                admin1: dataRow.admin1,
                admin2: dataRow.admin2,
                admin3: dataRow.admin3,
                lastUploadDate: 'unknown',
            },
            geometry: {
                type: 'Point',
                coordinates: [dataRow.long, dataRow.lat],
            },
        };

        featureSet.features.push(feature);
    }

    return featureSet;
};
export const convertStringDateToDate = (date: string) => {
    let finalDate;
    try {
        finalDate = format(parseISO(date), 'E LLL d yyyy');
    } catch (e) {
        finalDate = 'unknown';
    }

    return finalDate;
};

export const getCountryName = (countryCode: string): string => {
    const countryObj = iso.whereAlpha3(countryCode);

    // Kosovo is not available in the library
    if (countryCode === 'XK') return 'Kosovo';
    if (countryCode === 'TW') return 'Taiwan';

    return countryObj ? countryObj.country : countryCode;
};

export const getCountryISO2 = (countryCode: string): string => {
    const countryObj = iso.whereAlpha3(countryCode);

    return countryObj?.alpha2 || ''
}

export const getCountryISO3 = (countryCode: string): string => {
    const countryObj = iso.whereAlpha2(countryCode);

    return countryObj?.alpha3 || ''
}

export const getAdjustedLng = (lng: number, code: string): number => {
    if (code === 'MY') return lng + 11;
    if (code === 'NZ') return lng - 6;
    if (code === 'NO') return lng + 5;
    if (code === 'DK') return lng + 2;
    if (code === 'BH') return lng - 0.1;
    if (code === 'GR') return lng + 1;
    return lng;
};

export const getAdjustedLat = (lat: number, code: string): number => {
    if (code === 'MY') return lat - 2;
    if (code === 'NZ') return lat - 4;
    if (code === 'NO') return lat + 5;
    if (code === 'DK') return lat - 1;
    if (code === 'GR') return lat - 0.2;
    return lat;
};
