import {createAsyncThunk} from '@reduxjs/toolkit';
import {CountryDataRow} from 'models/CountryData';
import Papa from 'papaparse';
import countriesLookupTable from 'data/admin0-lookup-table.json';

// Fetch countries data from AWS S3 JSON file
export const fetchCountriesData = createAsyncThunk<
    {countriesData: CountryDataRow[], totalNumberOfCases: number, lastUpdateDate: string},
    void,
    { rejectValue: string }
>('app/fetchCountriesData', async (_, {rejectWithValue}) => {
    const dataUrl = process.env.REACT_APP_DATA_URL || '';


    try {
        if (!dataUrl) throw new Error('Data url missing');

        const latestFile = await fetch(dataUrl);
        if (latestFile.status !== 200)
            throw new Error('Fetching regional data failed');

        const reader = latestFile.body?.getReader();
        const result = await reader?.read();
        const decoder = new TextDecoder('utf-8');
        const csv = decoder.decode(result?.value);
        const results = Papa.parse(csv, {header: true});
        const confirmedData: any = results.data.filter(
            (data: any) => data['Case_status'] === 'confirmed',
        );

        const newData: any = []

        const typedCountriesLookupTable = countriesLookupTable as {
            [key: string]: any;
        };

        let lastUpdateDate = confirmedData[0].Data_up_to

        for (const dataEntry of confirmedData) {
            const countryCode = dataEntry.Country_ISO3
            const foundResource = newData.find((w: any) => w.code === countryCode)

            if(dataEntry.Data_up_to > lastUpdateDate) {
                lastUpdateDate = dataEntry.Data_up_to
            }

            if (foundResource) {
                foundResource.casecount += 1
                if(foundResource.lastUpdated < dataEntry.Data_up_to) {
                    foundResource.lastUpdated = dataEntry.Data_up_to
                }
                continue
            }

            const adm0Data: any = typedCountriesLookupTable.adm0.data.all[dataEntry.Country_ISO3]

            const newCountryData = {
                casecount: 1,
                code: countryCode,
                long: adm0Data.centroid[0],
                lat: adm0Data.centroid[1],
                _id: adm0Data.name,
                lastUpdated: dataEntry.Data_up_to
            }
            newData.push(newCountryData)

        }

        return {countriesData: newData, totalNumberOfCases: confirmedData.length, lastUpdateDate }
    } catch (err: any) {
        if (err.response) return rejectWithValue(err.response.message);
        throw err;
    }
});
