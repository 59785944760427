import mapboxgl from 'mapbox-gl';
import useMediaQuery from '@mui/material/useMediaQuery';
import ReactDOM from 'react-dom';
import {MapSourceDataEvent, EventData} from 'mapbox-gl';
import {useState, useEffect, useRef, useMemo} from 'react';

import provinceLookupTable from 'data/admin2-lookup-table.json';
import Legend from 'components/Legend';
import Loader from 'components/Loader';
import MapPopup from 'components/MapPopup';
import {useMapboxMap} from 'hooks/useMapboxMap';
import {LegendRow} from 'models/LegendRow';
import {RegionalViewColors} from 'models/Colors';
import {selectSelectedCountryInSideBar} from 'redux/App/selectors';
import {setSelectedCountryInSidebar, setPopup} from 'redux/App/slice';
import {useAppDispatch, useAppSelector} from 'redux/hooks';
import {selectRegionalData} from 'redux/RegionalView/selectors';
import {fetchRegionalData} from 'redux/RegionalView/thunks';
import {convertRegionalDataToFeatureSet, convertStringDateToDate, getCountryISO3} from 'utils/helperFunctions';

import {MapContainer} from 'theme/globalStyles';
import {PopupContentText} from './styled';


const dataLayers: LegendRow[] = [
    {label: '0-4', color: RegionalViewColors['0-4']},
    {label: '5-9', color: RegionalViewColors['5-9']},
    {label: '10-14', color: RegionalViewColors['10-14']},
    {label: '15-19', color: RegionalViewColors['15-19']},
    {label: '20-24', color: RegionalViewColors['20-24']},
    {label: '25+', color: RegionalViewColors['25+']},
];

export const RegionalView: React.FC = () => {
    const dispatch = useAppDispatch();

    const mapboxAccessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN || '';

    const [mapLoaded, setMapLoaded] = useState(false);
    const [currentPopup, setCurrentPopup] = useState<mapboxgl.Popup>();
    const provinceLookupTableData = provinceLookupTable.adm2.data.all as {
        [key: string]: any;
    };

    const regionalData = useAppSelector(selectRegionalData);
    const selectedCountry = useAppSelector(selectSelectedCountryInSideBar);

    const mapContainer = useRef<HTMLDivElement>(null);
    const map = useMapboxMap(mapboxAccessToken, mapContainer);
    const smallScreen = useMediaQuery('(max-width:1400px)');

    // Fetch regional data
    useEffect(() => { dispatch(fetchRegionalData()) }, []);

    // This variable should be momoized in order to improve performance
    const regionalDataFeatureSet = useMemo(() => {
        if (!regionalData || regionalData.length === 0) return undefined;

        return convertRegionalDataToFeatureSet(regionalData);
    }, [regionalData]);

    // Fly to country
    useEffect(() => {
        if (currentPopup) currentPopup.remove();
        if (!selectedCountry) return;

        // I need to identify regions and the best way to do this is by their location using lat and long
        const long = parseFloat(selectedCountry.code.split('#')[1])
        const lat = parseFloat(selectedCountry.code.split('#')[2])

        const foundRegion = Object.values(provinceLookupTableData)
            .find((pltd: any) => pltd.centroid[0] === long && pltd.centroid[1] === lat)

        if (foundRegion) {
            const foundRegionData: any = regionalData.find((rd: any) => rd.featureId === foundRegion.feature_id)
            const bounds = foundRegion.bounds
            const lastUploadDate = convertStringDateToDate(foundRegionData.lastUpdated);
            const popupTitle = foundRegion.name;

            map.current?.fitBounds(bounds, {padding: 250});

            const popupContent = (
                <PopupContentText>
                    {foundRegionData.casecount.toLocaleString()} confirmed case
                    {foundRegionData.casecount > 1 ? 's' : ''}
                </PopupContentText>
            );

            // This has to be done this way in order to allow for React components as a content of the popup
            const popupElement = document.createElement('div');
            ReactDOM.render(
                <MapPopup
                    title={popupTitle}
                    content={popupContent}
                    lastUploadDate={lastUploadDate}
                />,
                popupElement,
            );
            const mapRef: any = map.current;

            const popup = new mapboxgl.Popup({
                anchor: smallScreen ? 'center' : undefined,
            })
                .setLngLat(foundRegion.centroid)
                .setDOMContent(popupElement)
                .addTo(mapRef)
                .on('close', () =>
                    dispatch(
                        setPopup({
                            isOpen: false,
                            countryCode: '',
                        }),
                    ),
                );

            setCurrentPopup(popup);
        } else {
            if (currentPopup) currentPopup.remove();
            // Return to worldview
            map.current?.fitBounds([0, -12.4, 0, 70.15]);
        }
    }, [selectedCountry]);

    // Setup map
    useEffect(() => {
        const mapRef = map.current;
        if (!mapRef) return;

        mapRef.on('load', () => {
            setMapLoaded(true);
        });
    }, []);

    // Visualize regional data
    // It has to be done in separate function because regional data takes a lot of time to load
    useEffect(() => {
        const mapRef = map.current;
        if (
            !mapRef ||
            !mapLoaded ||
            !regionalDataFeatureSet
        )
            return;
        if (!mapRef.getSource('regionalData')) {
            mapRef.addSource('regionalData', {
                type: 'geojson',
                data: regionalDataFeatureSet,
            });
        }

        if (!mapRef.getSource('admin2Source')) {
            mapRef.addSource('admin2Source', {
                type: 'vector',
                url: 'mapbox://mapbox.boundaries-adm2-v3',
            });
        }


        const setData = () => {
            for (const regionRecord of regionalData) {
                mapRef.setFeatureState(
                    {
                        source: 'admin2Source',
                        sourceLayer: 'boundaries_admin_2',
                        id: regionRecord.featureId,
                    },
                    {
                        name: regionRecord.admin2,
                        numberOfCases: regionRecord.casecount,
                        geometry: [regionRecord.long, regionRecord.lat]
                    },
                );
            }
        };

        const setAfterLoad = (event: any) => {
            if (event.sourceID !== 'admin2Source' && !event.isSourceLoaded)
                return;
            setData();
            setMapLoaded(true);
            mapRef.off('sourcedata', setAfterLoad);
        };

        // If `adm2Data` source is loaded, call `setStates()`.
        if (mapRef.isSourceLoaded('admin2Source')) {
            setData();
            setMapLoaded(true);
        } else {
            mapRef.on('sourcedata', setAfterLoad);
        }

        if (!mapRef.getLayer('admin2Join')) {
            mapRef.addLayer(
                {
                    id: 'admin2Join',
                    type: 'fill',
                    source: 'admin2Source',
                    'source-layer': 'boundaries_admin_2',
                    paint: {
                        'fill-color': [
                            'case',
                            ['!=', ['feature-state', 'numberOfCases'], null],
                            [
                                'case',
                                ['==', ['feature-state', 'numberOfCases'], 0],
                                RegionalViewColors['empty'],
                                ['<', ['feature-state', 'numberOfCases'], 5],
                                RegionalViewColors['0-4'],
                                ['<', ['feature-state', 'numberOfCases'], 10],
                                RegionalViewColors['5-9'],
                                ['<', ['feature-state', 'numberOfCases'], 15],
                                RegionalViewColors['10-14'],
                                ['<', ['feature-state', 'numberOfCases'], 20],
                                RegionalViewColors['15-19'],
                                ['>=', ['feature-state', 'numberOfCases'], 25],
                                RegionalViewColors['25+'],
                                RegionalViewColors['empty'],
                            ],
                            RegionalViewColors['empty'],
                        ],
                    },
                },
                'waterway-label',
            );
        }

        if (!mapRef.getLayer('admin2JoinBorder')) {
            mapRef.addLayer(
                {
                    id: 'admin2JoinBorder',
                    type: 'line',
                    source: 'admin2Source',
                    'source-layer': 'boundaries_admin_2',
                    paint: {
                        'line-color': [
                            'case',
                            ['!=', ['feature-state', 'numberOfCases'], null],
                            [
                                'case',
                                ['==', ['feature-state', 'numberOfCases'], 0],
                                RegionalViewColors['empty'],
                                ['>', ['feature-state', 'numberOfCases'], 0],
                                '#0083bf', // Color of borders
                                RegionalViewColors['empty'],
                            ],
                            RegionalViewColors['empty'],
                        ],
                    },
                },
                'waterway-label',
            );
        }

        mapRef.on('click', 'admin2Join', (e) => {
            if (!e.features || !e.features[0].state.name) return;

            const popupTitle = e.features[0].state.name;
            const iso3 = getCountryISO3(e.features[0].properties?.iso_3166_1)
            const code = [iso3, ...e.features[0].state.geometry].filter(Boolean).join('#')

            dispatch(setSelectedCountryInSidebar({_id: popupTitle, code: code}));
        });

        // Change the mouse cursor to pointer when hovering above this layer
        mapRef.on('mouseenter', 'points', () => {
            mapRef.getCanvas().style.cursor = 'pointer';
        });

        // Change it back when it leaves.
        mapRef.on('mouseleave', 'points', () => {
            mapRef.getCanvas().style.cursor = '';
        });

        // Setup map listener to check if map has loaded
        const setAfterSourceLoaded = (e: MapSourceDataEvent & EventData) => {
            if (e.sourceID !== 'regionalData' && !e.isSourceLoaded) return;

            setMapLoaded(true);
            mapRef.off('sourcedata', setAfterSourceLoaded);
        };

        if (mapRef.isSourceLoaded('regionalData')) {
            setMapLoaded(true);
        } else {
            mapRef.on('sourcedata', setAfterSourceLoaded);
        }
    }, [mapLoaded, regionalDataFeatureSet]);

    return (
        <>
            {(!mapLoaded || !regionalDataFeatureSet) && <Loader/>}
            <MapContainer
                ref={mapContainer}
                isLoading={!mapLoaded || !regionalDataFeatureSet}
            />
            <Legend title="Confirmed cases" legendRows={dataLayers}/>
        </>
    );
};
