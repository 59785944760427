import ReactGA from 'react-ga4';
import {useEffect} from 'react';
import {ErrorBoundary} from 'react-error-boundary';
import {Routes, Route, Navigate, useLocation} from 'react-router-dom';

import ErrorAlert from 'components/ErrorAlert';
import ErrorFallback from 'components/ErrorFallback';
import Loader from 'components/Loader';
import PopupSmallScreens from 'components/PopupSmallScreens';
import SideBar from 'components/SideBar';
import TopBar from 'components/TopBar';
import CountryView from 'containers/CountryView';
import {RegionalView} from 'containers/RegionalView';
import {useCookieBanner} from 'hooks/useCookieBanner';
import {selectIsLoading, selectError} from 'redux/App/selectors';
import {fetchCountriesData} from 'redux/App/thunks';
import {useAppDispatch, useAppSelector} from 'redux/hooks';
import {selectIsRegionalViewLoading} from 'redux/RegionalView/selectors';

import {ErrorContainer} from './styled';


const App = () => {
    const env = process.env.NODE_ENV;
    const gaTrackingId = process.env.REACT_APP_GA_TRACKING_ID || '';

    useEffect(() => {
        if (env !== 'production' || gaTrackingId === '') return;

        ReactGA.initialize(gaTrackingId);
    }, [env, gaTrackingId]);

    // Init IUBENDA cookie banner
    const {initCookieBanner} = useCookieBanner();

    useEffect(() => {
        initCookieBanner();
    }, []);

    const location = useLocation();
    const dispatch = useAppDispatch();

    const isLoading = useAppSelector(selectIsLoading);
    const isRegionalViewLoading = useAppSelector(selectIsRegionalViewLoading);
    const error = useAppSelector(selectError);

    useEffect(() => {
        dispatch(fetchCountriesData())
    }, [dispatch]);

    // Track page views
    useEffect(() => {
        if (env !== 'production') return;

        ReactGA.send({hitType: 'pageview', page: location.pathname});
    }, [env, location]);

    return (
        <div className="App">
            {(isLoading || isRegionalViewLoading) && (
                <Loader/>
            )}
            <TopBar/>
            <PopupSmallScreens/>
            <ErrorBoundary
                FallbackComponent={ErrorFallback}
                onReset={() => window.location.reload()}
            >
                <SideBar/>
                <Routes>
                    <Route
                        path="/"
                        element={<Navigate replace to="/region"/>}
                    />
                    <Route path="/country" element={<CountryView/>}/>
                    <Route path="/region" element={<RegionalView/>}/>
                </Routes>
                {error && (
                    <ErrorContainer>
                        <ErrorAlert errorMessage={error}/>
                    </ErrorContainer>
                )}
            </ErrorBoundary>
        </div>
    );
};

export default App;
