export enum CountryViewColors {
    '<10' = 'rgba(154, 215, 238, 1)',
    '10-100' = 'rgba(123, 207, 242, 1)',
    '100-500' = 'rgba(107, 200, 238, 1)',
    '500-2k' = 'rgba(73, 189, 237, 1)',
    '2k-10k' = 'rgba(38, 163, 232, 1)',
    '10k+' = 'rgba(38, 137, 183, 1)',
    'Fallback' = 'rgba(215, 233, 237, 1)',
    'Outline' = 'rgba(0, 148, 226, 1)',
}

export enum RegionalViewColors {
    '0-4' = 'rgba(154, 215, 238, 1)',
    '5-9' = 'rgba(123, 207, 242, 1)',
    '10-14' = 'rgba(107, 200, 238, 1)',
    '15-19' = 'rgba(73, 189, 237, 1)',
    '20-24' = 'rgba(38, 163, 232, 1)',
    '25+' = 'rgba(38, 137, 183, 1)',
    'empty' = 'rgba(0, 0, 0, 0)',
}
