import {createAsyncThunk} from '@reduxjs/toolkit';
import {RegionalData, SearchResolution} from 'models/RegionalData';
import Papa from 'papaparse';
import provinceLookupTable from "data/admin2-lookup-table.json";

export const fetchRegionalData = createAsyncThunk<
    RegionalData[],
    void,
    { rejectValue: string }
>('regionalView/fetchRegionalData', async (_, {rejectWithValue}) => {
    const dataUrl = process.env.REACT_APP_DATA_URL;

    try {
        if (!dataUrl) throw new Error('Regional data url missing');

        const latestFile = await fetch(dataUrl);
        if (latestFile.status !== 200)
            throw new Error('Fetching regional data failed');

        const reader = latestFile.body?.getReader();
        const result = await reader?.read();
        const decoder = new TextDecoder('utf-8');
        const csv = decoder.decode(result?.value);
        const results = Papa.parse(csv, {header: true});
        const confirmedData: any = results.data.filter(
            (data: any) => data['Case_status'] === 'confirmed',
        );


        const regionalData: any = []

        const provinceLookupTableData = provinceLookupTable.adm2.data.all as {
            [key: string]: any;
        };

        for (const dataEntry of confirmedData) {
            let admin2 = dataEntry.Location_Province?.toUpperCase()
            if (admin2 == 'NK') admin2 = undefined;

            let admin3 = dataEntry.Location_District?.toUpperCase()
            if (admin3 == 'NK') admin3 = undefined;

            const country = dataEntry.Country?.toUpperCase()

            const _id = [admin2, country].filter(Boolean).join(', ')

            const foundResource = regionalData.find((w: any) => w._id == _id)

            if (foundResource) {
                foundResource.casecount += 1
                if (foundResource.lastUpdated < dataEntry.Data_up_to) {
                    foundResource.lastUpdated = dataEntry.Data_up_to
                }
                continue
            }

            const country_code = dataEntry.Country_ISO3


            // Get all provinces (adm2) for country
            const matches = Object.values(provinceLookupTableData).filter(
                (adm2Record: any) => adm2Record.iso_3166_1_alpha_3 === country_code)

            // Find exact name match
            let match = matches.find((w: any) => w.name.toUpperCase() === admin2)

            // Names can contain characters form other than english alphabet, so when none is find as exact match
            // we need to find it using other languages
            if (!match) {
                match = matches.find((w: any) => Object.values(w.names).find((y: any) => y.find((x: any) => x.toUpperCase().includes(admin2))))
            }
            if (!match) continue

            let search = SearchResolution.Country
            if (admin2) {
                if (admin3) search = SearchResolution.Admin3;
                else search = SearchResolution.Admin2;
            }
            const newDataEntry = {
                admin2,
                admin3,
                casecount: 1,
                country,
                country_code,
                long: match.centroid[0].toFixed(4),
                lat: match.centroid[1].toFixed(4),
                search,
                featureId: match.feature_id,
                _id,
                bounds: match.bounds,
                lastUpdated: dataEntry.Data_up_to
            }
            regionalData.push(newDataEntry)

        }


        return regionalData;


    } catch (error: any) {
        if (!error.response) throw error;

        return rejectWithValue(error.response.message);
    }
});
