import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {NavLink} from 'react-router-dom';

import GHListLogo from 'components/GHListLogo';

import {AppBarStyle, NavBar, StyledTooolbar} from './styled';


const TopBar = () => (
    <Box sx={{flexGrow: 1}}>
        <AppBarStyle position="static" className="navbar">
            <StyledTooolbar variant="regular" className="toolbar">
                <GHListLogo/>
                <NavBar>
                    <NavLink
                        to="/country"
                        className={({isActive}) =>
                            'nav-link countryViewNavButton' +
                            (isActive ? ' activated' : '')
                        }
                    >
                        <Typography variant="navbarlink" gutterBottom>
                            Country View
                        </Typography>
                    </NavLink>
                    <NavLink
                        to="/region"
                        className={({isActive}) =>
                            'nav-link regionalViewNavButton' +
                            (isActive ? ' activated' : '')
                        }
                    >
                        <Typography variant="navbarlink" gutterBottom>
                            Regional View
                        </Typography>
                    </NavLink>
                    <a
                        href={process.env.REACT_APP_DATA_PORTAL_URL || ''}
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        <Typography variant="navbarlink" gutterBottom>
                            Marburg Dataset
                        </Typography>
                    </a>
                    <a href="mailto:info@global.health?subject=Feedback regarding Global.health map">
                        <Typography variant="navbarlink" gutterBottom>
                            Feedback
                        </Typography>
                    </a>
                </NavBar>
            </StyledTooolbar>
        </AppBarStyle>
    </Box>
);

export default TopBar;
